const LANGS = {
    jp: { title: '日本語', code: '' },
    en: { title: 'English', code: 'en' },
}

const state = {
    lang: LANGS.jp,
    langs_list: Object.values(LANGS),
}

const getters = {
    all_translations_langs(state) {
        return state.langs_list
    },
    translation_lang(state) {
        return state.lang
    },
}

const mutations = {
    SET_LANG(state, lang) {
        state.lang = lang
    },
}

const actions = {
    setTranslationLanguage({ commit }, lang) {
        commit('SET_LANG', lang)
    },
}

export default {
    namespaced: false,
    state,
    getters,
    actions,
    mutations,
}
