<script>
export default {
    props: {
        withInfo: Boolean,
    },
}
</script>

<template>
    <v-switch v-bind="$attrs" v-on="$listeners">
        <slot v-for="(_, name) in $slots" :name="name" :slot="name" />

        <template v-if="withInfo" #label>
            <span>{{ label }}</span>
            <v-menu max-width="300" right bottom>
                <template #activator="{ on }">
                    <v-icon v-on="on" size="22" class="ml-1 my-n2">help_outline</v-icon>
                </template>
                <v-card>
                    <v-card-text>
                        <slot name="info" />
                    </v-card-text>
                </v-card>
            </v-menu>
        </template>

        <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
            <slot :name="name" v-bind="slotData" />
        </template>
    </v-switch>
</template>

<style lang="scss">
@import 'vuetify/src/styles/settings/_variables';
</style>
