<script>
export default {
    name: 'k-button',
    inheritAttrs: false,
}
</script>

<template>
    <v-btn v-bind="$attrs" v-on="$listeners">
        <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
        <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
            <slot :name="name" v-bind="slotData" />
        </template>
    </v-btn>
</template>
