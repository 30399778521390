<script>
import formatDate from '@/utils/format-date'
import formatDateRelative from '@/utils/format-date-relative'

export default {
    name: 'DateString',
    functional: true,
    props: {
        date: {
            required: true,
        },
        isJapanese: {
            type: Boolean,
            default: false,
        },
        relative: {
            type: Boolean,
            default: false,
        },
        force: {
            type: Boolean,
            default: false,
        },
        showTime: {
            type: Boolean,
            default: false,
        },
        tag: {
            type: String,
            default: 'span',
        },
        shortDate: {
            type: Boolean,
            default: false,
        },
        dateFormat: {
            type: String,
        },
    },

    render(h, { data, props }) {
        const { date, isJapanese, relative, force, showTime, tag, shortDate } = props

        if (!date) return h(tag, data, '-')

        const dateFormat = props.dateFormat || (isJapanese ? 'YYYY/MM/DD' : 'DD.MM.YYYY')
        const withTime = showTime ? ' H:mm' : ''
        let dateString = formatDate(date, dateFormat + withTime)
        let title = formatDate(date, dateFormat)
        if (isJapanese && !force) {
            dateString = formatDate(date, dateFormat + withTime, 'ja')
            title = formatDateRelative(date, 'ja')
        } else if (shortDate) {
            dateString = formatDate(date, props.dateFormat ? dateFormat : 'MMM D YYYY' + withTime)
        } else if (relative) {
            dateString = formatDateRelative(date, isJapanese ? 'ja' : '')
            title = formatDate(date, dateFormat + withTime, isJapanese ? 'ja' : '')
        } else {
            title = formatDateRelative(date)
        }

        dateString = dateString.replace('0000/', '').replace('.0000', '')

        return h(
            tag,
            {
                ...data,
                attrs: {
                    title,
                },
            },
            dateString
        )
    },
}
</script>
