<script>
import appConfig from '@/app.config'
import Error503 from '@/components/errors/503.vue'

export default {
    components: { Error503 },
    page: {
        // All subcomponent titles will be injected into this template.
        titleTemplate(title) {
            title = typeof title === 'function' ? title(this.$store) : title
            return title ? `${title} | ${appConfig.title}` : appConfig.title
        },
    },
    computed: {
        errorPage() {
            return this.$store.state.errorPage
        },
        is_503_error() {
            return this.errorPage && this.errorPage.code == '503'
        },
    },
}
</script>

<template>
    <div id="app">
        <error-503 v-if="is_503_error" />
        <router-view v-else />
    </div>
</template>

<!-- This should generally be the only global CSS in the app. -->
<style lang="scss">
// Allow element/type selectors, because this is global CSS.
// stylelint-disable selector-max-type, selector-class-pattern

// Normalize default styles across browsers,
// https://necolas.github.io/normalize.css/
@import 'normalize.css';

// Style loading bar between pages.
// https://github.com/rstacruz/nprogress
@import 'nprogress/nprogress.css';

// Design variables and utilities from src/design.
@import 'design/index.scss';
</style>
