<script>
import { ResizeObserver } from '@juggle/resize-observer'

import KDatePagination from '@/components/_base/KDatePagination.vue'

export default {
    components: { KDatePagination },
    props: {
        items: { type: Array, default: () => [] },
        headers: { type: Array, default: () => [] },
        hideEmptyContent: Boolean,
        options: Object,
        footerProps: Object,
        serverItemsLength: Number,
        showSelect: Boolean,
        disablePagination: Boolean,
        datePagination: Object,
        stickyColumnsCount: { type: Number, default: 1 },
    },
    data() {
        return {
            tableWrapperElement: null,
            tableElement: null,
            scrollDifference: 0,
            scrollLeft: 0,
            tableElementSizeObserver: null,
        }
    },
    mounted() {
        this.init()
    },
    destroyed() {
        if (this.tableElementSizeObserver) {
            this.tableElementSizeObserver.unobserve(this.tableElement)
        }
    },
    watch: {
        items() {
            window.requestAnimationFrame(() => {
                this.buildTableStyles()
            })
        },
    },
    computed: {
        has_items() {
            return this.items && this.items.length
        },
        has_pagination() {
            return (
                this.serverItemsLength > (this.items && this.items.length) ||
                (this.items && this.items.length > 10)
            )
        },
        hide_default_footer() {
            return Object.prototype.hasOwnProperty.call(this.$attrs, 'hide-default-footer')
                ? this.$attrs['hide-default-footer']
                : !this.has_pagination || !this.has_items
        },
        hide_default_header() {
            return Object.prototype.hasOwnProperty.call(this.$attrs, 'hide-default-header')
                ? this.$attrs['hide-default-header']
                : !this.has_items
        },
        is_scrollable_table() {
            return !!this.scrollDifference
        },
        show_left_shadow() {
            return this.is_scrollable_table && this.scrollLeft > 0
        },
        show_right_shadow() {
            return this.is_scrollable_table && this.scrollDifference - this.scrollLeft > 0
        },
    },
    methods: {
        init() {
            try {
                this.tableWrapperElement =
                    this.$refs.table.$el.querySelector('.v-data-table__wrapper')
                this.tableElement = this.tableWrapperElement.querySelector('table')
                this.tableWrapperElement.onscroll = () => {
                    this.scrollLeft = this.tableWrapperElement.scrollLeft
                }
                this.tableElementSizeObserver = new ResizeObserver(() => {
                    window.requestAnimationFrame(() => {
                        this.scrollDifference =
                            this.tableWrapperElement.scrollWidth -
                            this.tableWrapperElement.offsetWidth

                        this.buildTableStyles()
                    })
                })
                this.tableElementSizeObserver.observe(this.tableElement)
            } catch (error) {
                // eslint-disable-next-line
                console.warn(error)
            }
        },
        buildTableStyles() {
            let lastColumnWidth = 0
            let stickyColumnsCount = this.stickyColumnsCount

            if (this.showSelect) stickyColumnsCount++
            if (stickyColumnsCount <= 0) return
            if (!this.$refs['table']?.$el) return

            const headersElements = this.$refs['table'].$el.querySelectorAll('thead th')
            const bodyTrElements = this.$refs['table'].$el.querySelectorAll('tbody tr')

            headersElements.forEach((element, index) => {
                if (index < stickyColumnsCount) {
                    element.style['left'] = `${lastColumnWidth}px`
                    element.classList.add('k-table-sticky-column')
                    if (index + 1 === stickyColumnsCount || (this.showSelect && index === 0)) {
                        element.classList.add('k-table-sticky-column--border')
                    }
                    lastColumnWidth += element.getBoundingClientRect()?.width || element.offsetWidth
                }
            })

            bodyTrElements.forEach(trElement => {
                lastColumnWidth = 0
                trElement.querySelectorAll('td').forEach((element, index) => {
                    if (index < stickyColumnsCount) {
                        element.style['left'] = `${lastColumnWidth}px`
                        element.classList.add('k-table-sticky-column')
                        if (index + 1 === stickyColumnsCount || (this.showSelect && index === 0)) {
                            element.classList.add('k-table-sticky-column--border')
                        }

                        lastColumnWidth +=
                            element.getBoundingClientRect()?.width || element.offsetWidth
                    }
                })
            })
        },
    },
}
</script>

<template>
    <div>
        <v-data-table
            ref="table"
            class="k-table"
            :class="{
                'k-table--hide-empty': hideEmptyContent,
                'k-table--left-shadow': show_left_shadow,
                'k-table--right-shadow': show_right_shadow,
            }"
            v-bind="$attrs"
            v-on="$listeners"
            :hide-default-footer="hide_default_footer"
            :hide-default-header="hide_default_header"
            :mobile-breakpoint="0"
            :items="items || []"
            :headers="headers || []"
            :footer-props="footerProps"
            :disable-pagination="disablePagination || !!datePagination"
            :options="options"
            :server-items-length="serverItemsLength"
            :show-select="showSelect"
        >
            <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
                <slot :name="slot" v-bind="scope" />
            </template>
            <template #no-data>
                <slot name="no-data" />
            </template>
        </v-data-table>
        <div v-if="datePagination" class="date-pagination">
            <k-date-pagination
                v-bind="datePagination"
                @change="e => $emit('date-pagination:change', e)"
            />
        </div>
    </div>
</template>

<style lang="scss">
@import 'vuetify/src/styles/settings/_variables';

$last-column-border: #ddd;
$last-column-bg: #fff;
$last-column-bg--hover: #eee;
$last-column-bg--border: hsla(0, 0%, 100%, 0.12);
$last-column-bg--dark: #1e1e1e;
$last-column-bg--dark--hover: #616161;

@mixin last-column($bg-color, $border-color) {
    background: $bg-color;
    border-right: 1px solid $border-color;
}

@mixin last-column--hover($bg-color) {
    background: $bg-color;
}

@mixin left-shadow($bg-color) {
    content: '';
    width: 20px;
    display: block;
    position: absolute;
    right: -21px;
    top: 0;
    bottom: 0;
    background: linear-gradient(90deg, $bg-color 0%, rgba(255, 255, 255, 0) 75%);
    opacity: 0;
    visibility: hidden;
    transition: visibility 0s, opacity 200ms ease-in-out;
}

@mixin right-shadow($bg-color) {
    content: '';
    width: 30px;
    height: 48px;
    display: block;
    position: absolute;
    right: 0;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 25%, $bg-color 100%);
    opacity: 0;
    visibility: hidden;
    transition: visibility 0s, opacity 200ms ease-in-out;
}

.k-table {
    position: relative;
    table {
        // border-collapse: collapse;
        tr {
            th,
            td {
                text-align: left;
                white-space: nowrap;
                &:last-child {
                    text-align: right !important;
                }
                &:first-child {
                    text-align: left !important;
                    .v-input--selection-controls__input {
                        margin: 0;
                    }
                }
            }
            &.v-data-table__empty-wrapper {
                td {
                    text-align: center !important;
                }
            }
            &:not(.v-data-table__empty-wrapper) {
                &::after {
                    @include right-shadow($last-column-bg);
                }
            }
        }
        tbody {
            tr {
                &:not(.v-data-table__empty-wrapper) {
                    &:hover {
                        &::after {
                            @include right-shadow($last-column-bg--hover);
                        }
                    }
                }
            }
        }
        &--hide-empty {
            .v-data-table__empty-wrapper {
                display: none;
            }
        }
    }
    &--right-shadow table tr:not(.v-data-table__empty-wrapper) {
        &::after {
            opacity: 0.9 !important;
            visibility: visible !important;
        }
    }
    &.theme--dark {
        table tr:not(.v-data-table__empty-wrapper) {
            &::after {
                @include right-shadow($last-column-bg--dark);
            }
            &:hover {
                &::after {
                    @include right-shadow($last-column-bg--dark--hover);
                }
            }
        }
    }
}

.date-pagination {
    display: flex;
    justify-content: flex-end;
    border-top: thin solid rgba(0, 0, 0, 0.12);
}

@media #{map-get($display-breakpoints, 'sm-and-up')} {
    .k-table-sticky-column {
        position: sticky;
        z-index: 1;
    }

    .v-progress-linear--absolute {
        z-index: 2;
    }

    .k-table {
        table {
            // tbody {
            //     position: relative;
            //     z-index: 0;
            // }
            tr:not(.v-data-table__empty-wrapper) {
                &:hover {
                    td.k-table-sticky-column {
                        background: $last-column-bg--hover;
                    }
                }
                th,
                td {
                    &.k-table-sticky-column {
                        background: $last-column-bg;
                    }
                }
            }
        }
        table tr:not(.v-data-table__empty-wrapper) {
            &:hover td.k-table-sticky-column--border {
                @include last-column--hover($last-column-bg--hover);
                &::after {
                    @include left-shadow($last-column-bg--hover);
                }
            }
            th,
            td {
                &.k-table-sticky-column--border {
                    @include last-column($last-column-bg, $last-column-border);
                    &::after {
                        @include left-shadow($last-column-bg);
                    }
                }
            }
        }

        &--left-shadow {
            table tr:not(.v-data-table__empty-wrapper) {
                th,
                td {
                    &.k-table-sticky-column--border {
                        &::after {
                            opacity: 0.9 !important;
                            visibility: visible !important;
                        }
                    }
                }
            }
        }
    }
    .theme--dark {
        .k-table {
            table {
                tr:not(.v-data-table__empty-wrapper) {
                    &::after {
                        @include right-shadow($last-column-bg--dark);
                    }
                    &:hover td.k-table-sticky-column--border {
                        background: $last-column-bg--dark--hover;
                        &::after {
                            @include right-shadow($last-column-bg--dark--hover);
                        }
                    }
                    th,
                    td {
                        &.k-table-sticky-column--border {
                            background: $last-column-bg--dark;
                        }
                    }
                }
            }
            &:not(.k-table--show-select) table {
                tr:not(.v-data-table__empty-wrapper) {
                    &:hover td.k-table-sticky-column--border {
                        @include last-column--hover($last-column-bg--dark--hover);
                        &::after {
                            @include left-shadow($last-column-bg--dark--hover);
                        }
                    }
                    th,
                    td {
                        &.k-table-sticky-column--border {
                            @include last-column($last-column-bg--dark, $last-column-bg--border);
                            &::after {
                                @include left-shadow($last-column-bg--dark);
                            }
                        }
                    }
                }
            }
            &--show-select table {
                tr:not(.v-data-table__empty-wrapper) {
                    &:hover td:nth-child(2) {
                        @include last-column--hover($last-column-bg--dark--hover);
                        &::after {
                            @include left-shadow($last-column-bg--dark--hover);
                        }
                    }
                    th,
                    td {
                        &:nth-child(2) {
                            @include last-column($last-column-bg--dark, $last-column-bg--border);
                            &::after {
                                @include left-shadow($last-column-bg--dark);
                            }
                        }
                    }
                }
            }
        }
    }
}

@media #{map-get($display-breakpoints, 'xs-only')} {
    .k-table {
        table tr:not(.v-data-table__empty-wrapper) {
            &:hover {
                &::before {
                    @include left-shadow($last-column-bg--hover);
                }
            }
            &::before {
                @include left-shadow($last-column-bg);
                left: 0 !important;
                right: initial !important;
                top: initial !important;
                bottom: initial !important;
                height: 48px;
            }
        }
        &--left-shadow table tr:not(.v-data-table__empty-wrapper) {
            &::before {
                opacity: 0.9 !important;
                visibility: visible !important;
            }
        }
    }
    .theme--dark {
        .k-table {
            table tr:not(.v-data-table__empty-wrapper) {
                &:hover {
                    &::before {
                        @include left-shadow($last-column-bg--dark--hover);
                    }
                }
                &::before {
                    @include left-shadow($last-column-bg--dark);
                }
            }
        }
    }
}
</style>
