<script>
export default {
    page: {
        title: '403 Forbidden',
        meta: [{ name: 'description', content: '403' }],
    },
    props: {
        error: {
            type: [String, Error, Object],
            default: '',
        },
    },
}
</script>

<template>
    <v-container class="error-container">
        <div class="error-container__top">
            <v-icon class="red-text" size="100px">warning</v-icon>
            <div class="red-text" style="font-size: 100px; line-height: 100px">403</div>
        </div>
        <h1 class="text-center red-text mt-1">{{ $t('Forbidden') }}</h1>
        <h4 class="text-center red-text" v-if="error.message">{{ error.message }}</h4>
        <p class="text-center">
            {{
                $t(
                    'If you think that there may be some problem on KINCHAKU side, please contact our helpdesk.'
                )
            }}
        </p>
    </v-container>
</template>

<style lang="scss" scoped>
.error-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__top {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
</style>
