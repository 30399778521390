import { PROJECT_WITH_SUBTYPES } from '@/constants/projects'

export const getProjectTypeText = project => {
    if (!project.type) return

    let projectTypeText = Object.values(PROJECT_WITH_SUBTYPES).find(
        t => t.type === project.type && t.subtype === project.subtype
    )?.name

    if (!projectTypeText) {
        projectTypeText = project.type + ' ' + (project.subtype || '')
    }

    return projectTypeText
}

export const getPassType = project => {
    const type = project.type || ''
    const subtype = project.subtype || ''
    const is_monetary = subtype === 'monetary'

    switch (type) {
        case 'loyalty':
            if (subtype === 'stamp') return 'Stamp Card'
            if (subtype === 'member') return 'Membership Card'
            return type
        case 'service':
            return 'subscription'
        case 'coupon':
            if (is_monetary) return 'monetary'
            return type
        case 'voucher':
            if (is_monetary) return 'monetary'
            if (subtype === 'kaisuken') return 'kaisuken'
            return type
        case 'ticket':
            if (subtype === 'event') return 'event ticket'
            if (subtype === 'admission') return 'admission ticket'
            return type
        case 'transport':
            if (subtype == 'commuter') return 'commuter pass'
            return 'transport'
        default:
            return type
    }
}
