<script>
export default {
    props: {
        show: Boolean,
        width: { type: Number, default: 438 },
    },
    data() {
        return {
            screenWidth: 0,
        }
    },
    mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize)
            window.addEventListener('keydown', this.onKeydown)
        })
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize)
        window.removeEventListener('keydown', this.onKeydown)
    },
    computed: {
        is_mobile() {
            if (!this.screenWidth) return false
            return this.screenWidth < this.width
        },
        css_vars() {
            return {
                '--drawer-width': `${this.width}px`,
            }
        },
    },
    methods: {
        onResize() {
            this.screenWidth = window.innerWidth
        },
        onKeydown(e) {
            if (e.code === 27 || e.key === 'Escape' || e.key === 'Esc') this.$emit('close')
        },
    },
}
</script>

<template>
    <div :style="css_vars" class="drawer-container">
        <v-overlay :value="show">
            <div v-if="!is_mobile" class="drawer__content__left-part">
                <div class="close-btn">
                    <v-btn @click="$emit('close')" color="rgba(0,0,0,0.75)" fab small>
                        <v-icon color="white">close</v-icon>
                    </v-btn>
                </div>
            </div>
        </v-overlay>
        <div>
            <v-navigation-drawer
                :value="show"
                :width="width"
                class="drawer elevation-0"
                color="transparent"
                fixed
                right
                floating
                hide-overlay
                stateless
            >
                <div class="drawer__content">
                    <div class="drawer__content__main w-100 white">
                        <div v-if="is_mobile" class="close-btn-mobile">
                            <v-btn @click="$emit('close')" class="ml-1" fab x-small text>
                                <v-icon color="grey">arrow_back</v-icon>
                            </v-btn>
                        </div>
                        <slot v-bind="{ is_mobile }" />
                    </div>
                </div>
            </v-navigation-drawer>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import 'vuetify/src/styles/styles.sass';

.v-overlay::v-deep {
    .v-overlay__content {
        position: absolute;
        right: calc(#{$spacer} * 9 + var(--drawer-width));
        top: $spacer * 9;
    }
}

.drawer-container {
    position: relative;
    overflow: hidden;

    .close-btn-mobile {
        position: absolute;
        z-index: 1;
        top: $spacer * 9;
    }
    .drawer {
        // z-index: 11;
        &__content {
            display: flex;
            flex-direction: row;
            height: 100%;
            overflow: hidden;

            &__main {
                position: relative;
                overflow: hidden;
            }
        }
    }
}
</style>
