import Vue from 'vue'
import VueRouter from 'vue-router'
// https://github.com/declandewet/vue-meta
import VueMeta from 'vue-meta'
// Adds a loading bar at the top during page loads.
import NProgress from 'nprogress/nprogress'
NProgress.configure({ showSpinner: false })

import store from '../store'
import routes from './routes'
import { hasAccess } from './RBAC'
import { hasToken } from '../auth'

Vue.use(VueRouter)
Vue.use(VueMeta, {
    // The component option name that vue-meta looks for meta info on.
    keyName: 'page',
})

const router = new VueRouter({
    routes,
    mode: 'history',
    base: '/',
    // Simulate native-like scroll behavior when navigating to a new
    // route and using back/forward buttons.
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return {
                x: 0,
                y: 0,
            }
        }
    },
})

const originalPush = router.push
const originalReplace = router.replace
const routeErrorHandler = error => {
    const message = error.message || ''
    const isLocal = import.meta.env.VITE_ENV === 'local'

    if (!isLocal) {
        if (message.includes('Avoided redundant navigation to current location')) return
        if (message.includes('Navigation cancelled from')) return
        if (message.includes('Redirected when going from')) return
        if (error.name === 'NavigationDuplicated') return
    }

    throw error
}
router.push = function push(location) {
    return originalPush.call(this, location).catch(routeErrorHandler)
}
router.replace = function push(location) {
    return originalReplace.call(this, location).catch(routeErrorHandler)
}

// Before each route evaluates...
router.beforeEach(async (to, from, next) => {
    // Check if auth is required on this route (including nested routes).
    const authRequired = to.matched.some(route => route.meta.authRequired)
    const workspaceRequired = to.matched.some(route => route.meta.workspaceRequired)
    const redirectAuth = to.meta.redirectAuth

    if (redirectAuth && hasToken()) return next({ name: redirectAuth })

    if (to && to.name == 'signout') {
        to = {}
        return store.dispatch('signOut').then(() => redirectToSignin())
    }

    // If auth isn't required for the route, just continue.
    if (!authRequired) return next()

    if (!hasToken()) return redirectToSignin()

    if (hasToken() && store.getters.signed_in) {
        if (workspaceRequired) {
            if (store.getters.has_context) {
                if (
                    store.getters['has_context'] &&
                    !store.getters['is_full_company_business_info'] &&
                    store.getters.shadow_role === 'owner'
                ) {
                    return redirectSetBusinessInfo()
                }
                return next()
            } else return redirectToCreateWorkspace()
        } else return next()
    }
    NProgress.start()

    return store
        .dispatch('getUser')
        .then(async validUser => {
            NProgress.done()
            // Then continue if the token still represents a valid user,
            // otherwise redirect to login.
            if (validUser) {
                if (validUser.user_role === 'kiosk') {
                    return redirectToSignin({ showKioskAlert: true })
                }

                await store.dispatch('getWorkspaces')
                checkAndGetVenuesList(validUser)
                    .then(() => {
                        if (workspaceRequired) {
                            if (!validUser.origin?.context_id) redirectToCreateWorkspace()
                            else if (
                                store.getters['has_context'] &&
                                !store.getters['is_full_company_business_info'] &&
                                store.getters.shadow_role === 'owner'
                            ) {
                                redirectSetBusinessInfo()
                            } else next()
                        } else next()
                    })
                    .catch(e => {
                        return next(e)
                    })
            } else redirectToSignin()
        })
        .catch(error => {
            if (error == 401) {
                return redirectToSignin()
            }

            return next(error)
        })

    function redirectToSignin(query) {
        store.dispatch('clearSession')
        router.replace({
            path: store.getters.is_squareup_mode ? '/square/signin' : '/signin',
            query: { redirect: to.fullPath, ...query },
        })
    }
    function redirectToCreateWorkspace() {
        next({ name: 'workspace-create' })
    }
    function redirectSetBusinessInfo() {
        next({ name: 'workspace-business-info' })
    }
    function checkAndGetVenuesList(user) {
        return new Promise((resolve, reject) => {
            if (user.origin?.context_id) {
                store
                    .dispatch('getVenuesList')
                    .then(() => resolve())
                    .catch(error => reject(error))
            } else {
                resolve()
            }
        })
    }
})

// After navigation is confirmed, but before resolving...
router.beforeResolve((routeTo, routeFrom, next) => {
    const workspaceRequired = routeTo.matched.some(route => route.meta.workspaceRequired)
    if (workspaceRequired && !hasAccess(routeTo)) {
        const role = store.getters.role
        const root = router.match('/')
        const home = (root.meta?.roleHome || {})[role]
        if (home) return router.replace(home)

        return store.commit('SET_ERROR_PAGE', { code: '404' })
    }
    // hide error page on any route change
    store.commit('SET_ERROR_PAGE', null)
    // If this isn't an initial page load...
    if (routeFrom.name) {
        // Start the route progress bar.
        NProgress.start()
    }
    next()
})

// When each route is finished evaluating...
router.afterEach(() => {
    // Complete the animation of the route progress bar.
    NProgress.done()
})

router.onError(error => {
    if (
        error.message?.includes('Failed to fetch dynamically imported module') ||
        error.message?.includes('Importing a module script failed')
    ) {
        return window.location.reload()
    }

    if (error.response && error.response.status == 401) {
        store.dispatch('clearSession')
        return router.replace({ name: 'signin' })
    }
    if (error.response && error.response.status == 503) {
        NProgress.done()
        return store.commit('SET_ERROR_PAGE', { ...error, code: 503 })
    }
    // TODO: replace current page with error page instead of redirect (not possible currently)
    // router.replace({ name: '500', params: { error } })
    store.commit('SET_ERROR_PAGE', { ...error, code: 500 })
})

export default router
