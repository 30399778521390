export default {
    badge: 'バッジ',
    close: '閉じる',
    dataIterator: {
        noResultsText: '検索結果が見つかりません。',
        loadingText: '項目をロード中です...',
    },
    dataTable: {
        itemsPerPageText: '1ページあたりの行数：',
        ariaLabel: {
            sortDescending: '降順の並び替え。',
            sortAscending: '昇順の並び替え。',
            sortNone: 'ソートされていません。',
            activateNone: 'ソートを削除するには有効にしてください。',
            activateDescending: '降順の並び替えのためには有効にしてください。',
            activateAscending: '昇順のソートのためには有効にしてください。',
        },
        sortBy: 'ソート方式',
    },
    dataFooter: {
        itemsPerPageText: '1ページあたりの件数：',
        itemsPerPageAll: 'すべて',
        nextPage: '次のページ',
        prevPage: '前のページ',
        firstPage: '一ページ目',
        lastPage: '最後のページ',
        pageText: '{0}-{1} 件目 / {2}件',
    },
    datePicker: {
        itemsSelected: '{0}日付選択',
        nextMonthAriaLabel: '来月',
        nextYearAriaLabel: '来年',
        prevMonthAriaLabel: '前月',
        prevYearAriaLabel: '前年',
    },
    noDataText: 'データはありません。',
    carousel: {
        prev: '前のビジュアル',
        next: '次のビジュアル',
        ariaLabel: {
            delimiter: 'Carousel slide {0} of {1}',
        },
    },
    calendar: {
        moreEvents: 'さらに{0}',
    },
    fileInput: {
        counter: '{0} ファイル',
        counterSize: '{0} ファイル (合計 {1})',
    },
    timePicker: {
        am: 'AM',
        pm: 'PM',
    },
    pagination: {
        ariaLabel: {
            wrapper: 'ページネーションナビゲーション',
            next: '次のページ',
            previous: '前のページ',
            page: 'ページに移動 {0}',
            currentPage: '現在のページ、ページ {0}',
        },
    },
}
