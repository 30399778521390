// src/plugins/vuetify.js

import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

import jaVuetify from '@/vuetify.ja'

Vue.use(Vuetify)

const opts = {
    icons: {
        iconfont: 'mdiSvg',
    },
    theme: {
        // dark: true,
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                background: '#f7f8ff',
                primary: '#ddd',
                secondary: '#5f6368',
            },
            dark: {
                background: '#f7f8ff',
            },
        },
    },
    lang: {
        locales: { ja: jaVuetify },
    },
}

export default new Vuetify(opts)
