<script>
export default {
    page: {
        title: '503 Service Unavailable',
        meta: [{ name: 'description', content: '503' }],
    },
}
</script>

<template>
    <div class="service-unavailable v-application">
        <div class="service-unavailable__container">
            <div class="service-unavailable__container__image">
                <img src="/images/kinchaku_logo.svg" class="block bg-indigo-light--background" />
            </div>
            <h1 class="service-unavailable__container__title">
                {{ $t('under maintenance') }}
            </h1>
            <div class="service-unavailable__container__description">
                <template v-if="$root.is_japanese">
                    現在、サービスはメンテナンス中です。
                    <br />
                    もうすぐ戻ります！
                </template>
                <template v-else>
                    The service is currently undergoing maintenance.
                    <br />
                    We'll be back soon!
                </template>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.service-unavailable {
    height: 100vh;
    overflow: hidden;
    background-color: #f1f2fa;
    &::after {
        display: block;
        content: '';
        width: 100%;
        height: 100px;
        background-color: #fff;
        border-top-left-radius: 100%;
        border-top-right-radius: 100%;
        position: absolute;
        bottom: 0;
    }

    &__container {
        background-image: url('/images/gears.png');
        background-repeat: no-repeat;
        background-position: center bottom 64px;
        background-size: min(calc(100% - 128px), 289px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 32px;
        position: relative;
        z-index: 10;
        height: 100%;
        max-width: 600px;
        margin: auto;

        &__title {
            text-transform: capitalize;
            font-weight: bold;
            font-size: 24px;
            margin-top: 32px;
            margin-bottom: 16px;
        }
        &__description {
            text-align: center;
            font-size: 16px;
            margin-bottom: min(100%, 254px);
            color: #6b7280;
        }
    }
}
</style>
