<script>
import KustomerHeader from './Header.vue'
import KustomerFeedbackList from './FeedbacksList.vue'
import KustomerFeedbackForm from './Form.vue'

export default {
    props: ['params', 'labels', 'onClose'],

    data() {
        return {
            selectedFeedback: undefined,
        }
    },

    methods: {
        setSelectedFeedback(feedback) {
            this.selectedFeedback = feedback
        },
    },

    components: {
        KustomerHeader,
        KustomerFeedbackList,
        KustomerFeedbackForm,
    },
}
</script>

<template>
    <div class="kustomer-popup">
        <v-btn text small icon @click="onClose" class="popup-close-button">
            <v-icon>close</v-icon>
        </v-btn>
        <kustomer-header>
            <h1 slot="title" :style="{ color: params.colors.primary }">
                {{ labels.title | translate }}
            </h1>
        </kustomer-header>
        <div class="kustomer-container">
            <kustomer-feedback-list
                :feedbacks="params.feedbacks"
                :labels="labels"
                @selected="setSelectedFeedback"
            />
            <kustomer-feedback-form
                :feedback="selectedFeedback"
                :params="params"
                :labels="labels"
                @unselected="selectedFeedback = undefined"
            />
        </div>
    </div>
</template>

<style lang="scss" scoped>
.popup-close-button {
    position: absolute;
    top: 8px;
    right: 8px;
}
</style>
