import api from '@/api'
import omitBy from 'lodash/omitBy'

const state = {}

const mutations = {}

const actions = {
    getTransactions({ dispatch }, { pagination, filters = {} }) {
        pagination ? delete pagination.totalItems : false
        const definedFilters = omitBy(filters, v => [null, undefined].includes(v))

        return api
            .get('transactions', { params: { ...definedFilters, ...pagination } })
            .catch(e => dispatch('handleError', e))
    },
    exportTransactions({ dispatch }, { filters = {}, selectedColumns = [] }) {
        const definedFilters = omitBy(filters, v => [null, undefined].includes(v))

        return api
            .post('/transactions/export', { ...definedFilters, selectedColumns })
            .catch(e => dispatch('handleError', e))
    },
    getTransaction({ dispatch }, id) {
        return api
            .get(`transactions/${id}`)
            .then(({ data }) => data)
            .catch(e => dispatch('handleError', e))
    },
    refundTransaction({ dispatch }, id) {
        return api.delete(`transactions/${id}`).catch(e => dispatch('handleError', e))
    },
}

export default {
    namespaced: false,
    state,
    actions,
    mutations,
}
