import api from '@/api'

const state = {
    list: null,
}

const mutations = {
    RESET(state) {
        state.list = null
    },
    SET_TRANSPORT_PROJECTS(state, data) {
        state.list = data
    },
}

const actions = {
    getTransportProjects({ commit, dispatch }, params = {}) {
        let { pagination, search, filters, archived } = params
        const options = { ...pagination, search, filters }
        if (typeof archived !== 'undefined') {
            options.archived = archived
        }
        return api
            .get('/projects/list/transport', { params: options })
            .then(data => {
                commit('SET_TRANSPORT_PROJECTS', data)
                return data
            })
            .catch(e => dispatch('handleError', e))
    },
    createTransportProject({ dispatch, commit }, project) {
        project.type = 'transport'
        return dispatch('createProject', project).then(data => {
            dispatch('getUser')
            commit('SET_TRANSPORT_PROJECTS')
            return data
        })
    },
    updateTransportProject({ dispatch, commit }, project) {
        return dispatch('updateProject', project).then(data => {
            commit('SET_TRANSPORT_PROJECTS')
            return data
        })
    },

    resetTransports({ commit }) {
        commit('SET_TRANSPORT_PROJECTS')
    },
    exportFaresAsCSV({ dispatch }, { project_id, filters }) {
        return api
            .post(`/fares/${project_id}/export`, filters)
            .then(({ location }) => location) // location - path to the created file.
            .catch(e => dispatch('handleError', e))
    },
}

export default {
    namespaced: false,
    state,
    actions,
    mutations,
}
