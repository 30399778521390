import Vue from 'vue'

import vuetify from '@/plugins/vuetify'

import KList from '@/components/_base/list/KList.vue'
import KListItem from '@/components/_base/list/KListItem.vue'
import KListItemContent from '@/components/_base/list/KListItemContent.vue'
import KListItemTitle from '@/components/_base/list/KListItemTitle.vue'
import KListItemSubtitle from '@/components/_base/list/KListItemSubtitle.vue'

import KDateField from '@/components/_base/KDateField.vue'
import KDrawerModal from '@/components/_base/KDrawerModal.vue'
import KDrawerCard from '@/components/_base/KDrawerCard.vue'
import KSwitch from '@/components/_base/KSwitch.vue'
import KSelect from '@/components/_base/KSelect.vue'
import KButton from '@/components/_base/KButton.vue'
import KTextField from '@/components/_base/KTextField.vue'
import KDataTable from '@/components/_base/KDataTable.vue'
import KCardDataTable from '@/components/_base/KCardDataTable.vue'
import KBtnUpload from '@/components/_base/KBtnUpload.vue'
import KExpirationPicker from '@/components/_base/KExpirationPicker.vue'
import KSalesPicker from '@/components/_base/KSalesPicker.vue'
import KIcon from '@/components/_base/KIcon.vue'
import KChip from '@/components/_base/KChip.vue'

import KCardTitle from '@/components/cardTitle.vue'
import KDate from '@/components/dateString.vue'
import Spinner from '@/components/spinner.vue'

import Layout from '@/layouts/Main.vue'
import KDialog from '@/components/dialog.vue'
import KConfirm from '@/components/confirm.vue'
import KToolbar from '@/components/toolbar.vue'

Vue.component('KList', KList, { context: { vuetify } })
Vue.component('KListItem', KListItem, { context: { vuetify } })
Vue.component('KListItemContent', KListItemContent, { context: { vuetify } })
Vue.component('KListItemTitle', KListItemTitle, { context: { vuetify } })
Vue.component('KListItemSubtitle', KListItemSubtitle, { context: { vuetify } })

Vue.component('KDateField', KDateField, { context: { vuetify } })
Vue.component('KDrawerModal', KDrawerModal, { context: { vuetify } })
Vue.component('KDrawerCard', KDrawerCard, { context: { vuetify } })
Vue.component('KDataTable', KDataTable, { context: { vuetify } })
Vue.component('KCardDataTable', KCardDataTable, { context: { vuetify } })
Vue.component('KBtnUpload', KBtnUpload, { context: { vuetify } })
Vue.component('KExpirationPicker', KExpirationPicker, { context: { vuetify } })
Vue.component('KSalesPicker', KSalesPicker, { context: { vuetify } })
Vue.component('KIcon', KIcon, { context: { vuetify } })
Vue.component('KChip', KChip, { context: { vuetify } })

Vue.component('KSwitch', KSwitch, { context: { vuetify } })
Vue.component('KSelect', KSelect, { context: { vuetify } })
Vue.component('KButton', KButton, { context: { vuetify } })
Vue.component('KTextField', KTextField, { context: { vuetify } })

Vue.component('Spinner', Spinner)
Vue.component('KCardTitle', KCardTitle)

Vue.component('Layout', Layout, { context: { vuetify } })
Vue.component('KDialog', KDialog, { context: { vuetify } })
Vue.component('KConfirm', KConfirm, { context: { vuetify } })
Vue.component('KToolbar', KToolbar, { context: { vuetify } })
Vue.component('KDate', KDate, { context: { vuetify } })
