<script>
export default {
    props: {
        size: {
            type: String,
            default: 'md',
        },
    },
}
</script>

<template>
    <div :class="{ 'k-chip--md': size === 'md', 'k-chip--sm': size === 'sm' }" class="k-chip">
        <slot />
    </div>
</template>

<style lang="scss" scoped>
@import 'vuetify/src/styles/styles.sass';

.k-chip {
    border-radius: $spacer;
    background-color: #3f51b51f;
    color: #3f51b5;
    font-weight: 550;

    &--md {
        padding: $spacer * 1.5 $spacer * 3;
    }

    &--sm {
        padding: 0 $spacer * 2;
    }
}
</style>
