export const getTimeByString = timeString => {
    let HH, mm

    if (timeString && typeof timeString === 'string') {
        const timeArray = timeString.match(/(\d*):(\d*)/)

        if (timeArray) {
            HH = timeArray[1]
            mm = timeArray[2]
        }
    }

    return {
        HH,
        mm,
        // [Symbol.toPrimitive]: hint => {
        //     if (HH && mm) return `${HH}:${mm}`
        //     else return ''
        // },
    }
}

export const getTimeString = ({ HH, mm }) => {
    return `${HH + 100}`.substring(1) + ':' + `${mm + 100}`.substring(1)
}
