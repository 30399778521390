// `tinycolor2` is a dependency of `vue-color`
import tinycolor from 'tinycolor2'

// returns all design colors including calculated
export function calculateDesignColors(colors) {
    const { background_color, text_color } = colors
    if (!background_color || !text_color) return colors
    return {
        ...colors,
        heading_color: getHeadingColor(colors),
        unstamped_color: getUnstampedColor(colors),
        strip_color: getStripColor(colors),
    }
}

/*
if L $background-color < L $text-color then
$heading-color: desaturate(lighten($background-color, 30%), 30%);

if L $background-color > L $text-color then
$heading-color: desaturate(darken($background-color, 45%), 40%);
*/
export function getHeadingColor({ background_color, text_color }) {
    if (!background_color || !text_color) return null
    const textColor = tinycolor(text_color)
    const backgroundColor = tinycolor(background_color)
    const textHSL = textColor.toHsl()
    const backgroundHSL = backgroundColor.toHsl()
    if (backgroundHSL.l < textHSL.l) {
        return backgroundColor.lighten(30).desaturate(30).toHexString()
    } else {
        return backgroundColor.darken(45).desaturate(40).toHexString()
    }
}

// $strip-color: mix($base-color, $text-color, 85%)
export function getStripColor({ background_color, text_color }) {
    if (!background_color || !text_color) return null
    // Important: colors order in `tinycolor` and `SASS` are different - swap them
    return tinycolor.mix(text_color, background_color, 85).toHexString()
}

// $state-unstamped: mix($base-color, $text-color, 60%)
export function getUnstampedColor({ background_color, text_color }) {
    if (!background_color || !text_color) return null
    // Important: colors order in `tinycolor` and `SASS` are different - swap them
    return tinycolor.mix(text_color, background_color, 60).toHexString()
}
