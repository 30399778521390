import api from '@/api'

const state = {}

const mutations = {}

const actions = {
    getRewardStats({ dispatch }, params) {
        return api.post('/rewards/stats', params).catch(e => dispatch('handleError', e))
    },
    exportRewardStatsAsCSV({ dispatch }, params) {
        return api
            .post('/rewards/stats/export', params)
            .then(({ location }) => location) // location - path to the created file.
            .catch(e => dispatch('handleError', e))
    },
}

export default {
    namespaced: false,
    state,
    actions,
    mutations,
}
