import api from '@/api'

const state = {}

const mutations = {}

const actions = {
    getAnnouncements({ dispatch }, params = {}) {
        return api
            .get(`/announcements`, { params: { ...params?.pagination, lang: params?.lang } })
            .catch(e => dispatch('handleError', e))
    },
}

export default {
    namespaced: false,
    state,
    actions,
    mutations,
}
