<script>
import { parse, isPast } from 'date-fns'

import { EMAIL_REGEX, URL_REGEX, URL_HTTPS_REGEX } from '@/utils/validation'
import { getTimeByString } from '@/utils/time-string'
import { isDurationMoreThan } from '@/utils/time-duration'

export default {
    data() {
        return {
            rules: {
                required: v => !!v || this.$t('Required Field'),
                requiredArray: v => (!!v && !!v.length) || this.$t('Required Field'),
                requiredTimezone: v => !!v || v == 0 || this.$t('Required Field'),
                name: v => {
                    return (
                        !v ||
                        (v && v.length <= 30) ||
                        this.$t('The value must be less than or equal {count} characters.', {
                            count: 30,
                        })
                    )
                },
                title: v =>
                    !v ||
                    (v && v.length <= 30) ||
                    this.$t('The value must be less than or equal {count} characters.', {
                        count: 30,
                    }),
                email: v => !v || EMAIL_REGEX.test(v) || this.$t('Must be a valid email'),
                phone: v =>
                    !v ||
                    /^[\+0]?[\d()\s-–]{8,17}$/.test(v) ||
                    this.$t('Please input correct phone number'),
                url: v => !v || URL_REGEX.test(v) || this.$t('Must be a valid URL'),
                https: v => !v || URL_HTTPS_REGEX.test(v) || this.$t('Must be a valid URL'),
                quantity: v =>
                    !v ||
                    (v >= 1 && v == parseInt(v, 10)) ||
                    this.$t('Please input correct quantity'),
                contact: v => !v || !!v.id || this.$t('Please select existing contact from list'),
                katakana: v => {
                    const pattern = /^[ア-ン゛゜ァ-ォャ-ョー「」、]+$/
                    return !v || pattern.test(v) || this.$t('Please input katakana only')
                },
                kana: v => {
                    const pattern = /^[ぁ-んァ-ン\wー「」、゛゜]+$/
                    return !v || pattern.test(v) || this.$t('Please input kana only')
                },
                withoutSpecialCharacters: v => {
                    const pattern = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/

                    return !v || !pattern.test(v) || this.$t("Please don't use special characters")
                },
                withoutSpecialCharactersArray: v => {
                    const pattern = /[`@#$%^&*()_+\-=\[\]{};':"\\|<>\/?~]/
                    if (v && Array.isArray(v)) {
                        return (
                            !v.some(item => pattern.test(item)) ||
                            this.$t("Please don't use special characters")
                        )
                    }
                    return !v || !pattern.test(v) || this.$t("Please don't use special characters")
                },
                latin: v =>
                    !v || !!v.match('^[\\w\\s-]+$') || this.$t('Only latin characters please'),
                positive: v => v > 0 || this.$t('Must be greater than 0'),
                number: v =>
                    v === undefined ||
                    v === null ||
                    v === '' ||
                    v > 0 ||
                    this.$t('The value must be positive number.'),
                numberOrZero: v =>
                    v === undefined ||
                    v === null ||
                    v === '' ||
                    v >= 0 ||
                    this.$t('The value must be positive number.'),
                price: v =>
                    v > 0 || [0, '0'].includes(v) || this.$t('The value must be positive number.'),
                numberMin: min => v =>
                    v === undefined ||
                    v === null ||
                    v === 0 ||
                    v === '' ||
                    v >= min ||
                    this.$t('The number may not be less than {min}.', { min }),
                numberMax: max => v =>
                    v === undefined ||
                    v === null ||
                    v === '' ||
                    v <= max ||
                    this.$t('The number may not be greater than {max}.', { max }),
                priceMin: min => v =>
                    v === undefined ||
                    v === null ||
                    v === 0 ||
                    v === '' ||
                    v >= min ||
                    this.$t('The price may not be less than {min}.', { min }),
                priceMax: max => v =>
                    v === undefined ||
                    v === null ||
                    v === '' ||
                    v <= max ||
                    this.$t('The price may not be greater than {max}.', { max }),
                csvFileType: v => {
                    return (
                        v === undefined ||
                        v === null ||
                        !!v ||
                        // !v.type ||
                        // v.type === 'text/csv' ||
                        this.$t('Invalid csv file')
                    )
                },
                jsonFileType: v => {
                    return (
                        v === undefined ||
                        v === null ||
                        !!v ||
                        // !v.type ||
                        // v.type === 'text/csv' ||
                        this.$t('Invalid json file')
                    )
                },
                minCharacters: count => v =>
                    !v ||
                    v.length >= count ||
                    this.$t('The value must be greater than or equal {count} characters.', {
                        count,
                    }),
                maxCharacters: count => v =>
                    !v ||
                    v.length <= count ||
                    this.$t('The value must be less than or equal {count} characters.', {
                        count: count,
                    }),
                emails: v => {
                    return (
                        !v ||
                        !v.length ||
                        v.reduce((r, i) => r && EMAIL_REGEX.test(i), true) ||
                        this.$t('Must be a valid email')
                    )
                },
                periodDurationYears: years => v => {
                    return (
                        v <= years * 365 ||
                        this.$t(
                            '{count} years maximum|{count} year maximum|{count} years maximum',
                            { count: years }
                        )
                    )
                },
                periodDurationMonths: count => v => {
                    return (
                        v <= count || this.$t('1 month maximum|{count} months maximum', { count })
                    )
                },
                futureDate: v => {
                    if (!v) return true

                    const partsOfDate = v.match(/(\d+)[\.\/](\d+)[\.\/](\d+)/)

                    if (!partsOfDate) return true

                    const date = parse(
                        (this.$root.is_japanese
                            ? `${partsOfDate[1]}-${partsOfDate[2]}-${partsOfDate[3]}`
                            : `${partsOfDate[3]}-${partsOfDate[2]}-${partsOfDate[1]}`) + ' 23:59:59'
                    )

                    return (
                        !isPast(date) || this.$t("Past date can't be selected for the expiration")
                    )
                },
                percentOff: v =>
                    (v > 0 && v <= 100) ||
                    this.$t('The value must be between {min} and {max}.', { min: 0, max: 100 }),
                numberBetween: (min, max) => v =>
                    (v >= min && v <= max) ||
                    this.$t('The value must be between {min} and {max}.', { min, max }),
                time: v => {
                    const time = getTimeByString(v)
                    return !v || (!!v && !!time.HH && !!time.mm) || this.$t('Required Field')
                },
                coordinate: v =>
                    !v ||
                    (v >= -180 && v <= 180) ||
                    this.$t('Must be within interval from 0 to 180.'),
                proximityUUID: v =>
                    !v ||
                    v.length === 36 ||
                    this.$t('Input correct UUID. Example: bf393233-3bf8-516f-b57f-1ac0c0bc2972'),
                availabilityTo: from => v => {
                    // zero is same day. finish accpting must be either also zero or negative
                    if (v[0] == '0' && (from[0] == '0' || from[0] == '-')) {
                        return true
                    }
                    return (
                        isDurationMoreThan(v, from) ||
                        this.$t('Must be more than finish accepting bookings')
                    )
                },

                amountDigits: amount => v =>
                    (v && v.length == amount) ||
                    this.$t('Amount of digits must be:') + ` ${amount}`,
                amountDigitsUpTo: amount => v =>
                    (v && (v.length == amount - 1 || v.length == amount)) ||
                    this.$t('Amount of digits must be up to:') + ` ${amount}`,

                squareImageMin: min => v =>
                    (v && v.width === v.height && v.width >= min) ||
                    this.$t(`Must be square and at least ${min}x${min}px`),
                imageSizeMb:
                    (mb = 10) =>
                    v => {
                        if (!v || !v.size) return true

                        return (
                            (v && v.size <= mb * Math.pow(2, 20)) ||
                            this.$t('File size limit {count}Mb.', { count: mb })
                        )
                    },
                base64: v =>
                    !v ||
                    /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/.test(v) ||
                    this.$t(`Must be a valid base64 encoded string`),
            },
        }
    },
}
</script>
