<script>
export default {
    page: {
        title: '500 Server Error',
        meta: [{ name: 'description', content: '500' }],
    },
    props: {
        error: {
            type: [String, Error, Object],
            default: '',
        },
    },
    methods: {
        reloadPage() {
            location.reload()
        },
    },
}
</script>

<template>
    <v-container class="error-container">
        <div class="error-container__top">
            <v-icon class="red-text" size="100px">warning</v-icon>
            <div class="red-text" style="font-size: 100px; line-height: 100px">500</div>
        </div>
        <h1 class="text-center red-text mt-1">{{ $t('Server Error') }}</h1>
        <h4 class="text-center red-text" v-if="error.message">{{ error.message }}</h4>
        <p class="text-center">
            <template v-if="$root.is_japanese">
                接続に問題が発生しました。ページを再読み込みしてみてください。
                <br />
                再読み込みしても改善されない場合、お手数ですがヘルプデスクまで
                <a href="https://help.kinchaku.com/" target="_blank">お問い合わせ</a>
                ください。
            </template>
            <template v-else>
                If you are experiencing issues, please try reloading the page.
                <br />
                If the situation does not improve even after reloading, please
                <a href="https://help.kinchaku.com/" target="_blank">contact the help desk</a>
                .
            </template>
        </p>
        <v-btn @click="reloadPage" class="mt-5 capitalize-first" color="primary" x-large>
            {{ $t('reload') }}
        </v-btn>
    </v-container>
</template>

<style lang="scss" scoped>
.error-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__top {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
</style>
