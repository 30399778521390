export default {
    tabText: '    ',
    strongText: 'strong',
    italicText: 'italic',
    strikethroughText: 'strike through',
    headingText: 'heading',
    lineText: '----',
    quoteText: 'quote',
    codeText: 'code',
    linkText: 'link',
    linkTip: 'link address',
    imageText: 'image',
    imageTip: 'image address',
    imageTitle: 'image title',
    tableText: 'header1 | header2\n---|---\nrow1col1 | row1col2\nrow2col1 | row2col2\n',
    ulText: 'list',
    olText: 'list',
}
