import { convertToTimeZone } from 'date-fns-timezone'
import { parse, format } from 'date-fns'
import jaLocale from 'date-fns/locale/ja'

const DEFAULT_TIMEZONE = 'Asia/Tokyo'
const REQUEST_DATE_FORMAT = 'YYYY-MM-DD HH:mm'

export function toTokyoTimezone(date) {
    return format(
        convertToTimeZone(parse(date), { timeZone: DEFAULT_TIMEZONE }),
        REQUEST_DATE_FORMAT
    )
}

export default function formatDate(date, template, locale) {
    date = parse(date)
    let options = {}
    if (locale == 'ja' || locale == 'ja-jp') {
        options.locale = jaLocale
    }
    return format(date, template ? template : 'MMM Do, YYYY', options)
}
