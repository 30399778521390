<script>
import { mapActions } from 'vuex'

export default {
    props: {
        rules: { type: Array, required: false },
        hideDetails: { type: [Boolean, String], required: false },
        accept: { type: String, required: false },
    },
    data() {
        return {
            file: undefined,
            key: 0,
        }
    },
    watch: {
        file() {
            this.onChangeFile(this.file)
        },
    },
    methods: {
        ...mapActions(['handleError', 'resetError']),
        onClickUpload() {
            this.$refs['fileInput'].$refs.input.click()
        },
        onChangeFile(file) {
            if (!file) return

            this.resetError()

            if (!this.$refs['fileInput'].validate()) {
                this.handleError(this.$refs['fileInput'].errorBucket.join(' ,')).catch(() => {})
            } else {
                this.$emit('change', file)
            }
            this.reset()
        },
        reset() {
            this.file = null
            this.key++
        },
    },
}
</script>

<template>
    <div class="d-inline">
        <slot v-bind="{ click: onClickUpload }" />
        <v-file-input
            v-model="file"
            :key="key"
            :rules="rules"
            :accept="accept"
            ref="fileInput"
            class="d-none"
        />
    </div>
</template>

<style lang="scss" scoped>
@import 'vuetify/src/styles/styles.sass';
.file-input::v-deep {
    .v-input__prepend-outer {
        display: none;
    }
    .v-input__slot {
        display: none;
    }
    .v-messages {
        margin-top: $spacer * 2;
    }
}
</style>
