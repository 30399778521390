import { calculateDesignColors } from '@/utils/colors'

const state = {
    list: [],
    active_design: {
        colors: calculateDesignColors({
            background_color: '#FFF',
            text_color: '#000',
        }),
    },
}

const mutations = {
    RESET(state) {
        state.designs = []
        state.active_design = null
    },
    SET_DESIGNS(state, data) {
        state.list = data
    },
    SET_ACTIVE_DESIGN(state, design) {
        if (design && design.id) {
            state.active_design = {
                ...design,
            }
        } else {
            state.active_design = design
        }
    },
}

const actions = {
    getDesigns({ commit, dispatch }) {
        const designs = getDefaultDesigns()
        commit('SET_DESIGNS', designs)
        dispatch('setActiveDesign')

        return designs
    },
    setActiveDesign({ commit, state }, data) {
        const design = data || state.list[0] || null
        commit('SET_ACTIVE_DESIGN', design)
    },
}

export default {
    namespaced: false,
    state,
    actions,
    mutations,
}

function getDefaultDesigns() {
    return [
        { colors: { background_color: '#FFF', text_color: '#000' } },
        { colors: { background_color: '#3F51B5', text_color: '#ffffff' } },
        { colors: { background_color: '#B53FB3', text_color: '#ffffff' } },
        { colors: { background_color: '#3FB57A', text_color: '#ffffff' } },
        { colors: { background_color: '#B53F3F', text_color: '#ffffff' } },
        { colors: { background_color: '#1A2540', text_color: '#ffffff' } },
    ].map(d => ({
        ...d,
        colors: {
            ...calculateDesignColors(d.colors),
            default: d.colors,
            modified: false,
        },
    }))
}
