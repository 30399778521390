import api from '@/api'

const state = {
    list: null,
}

const mutations = {
    RESET(state) {
        state.list = null
        state.tags = []
    },
    SET_COUPONS(state, data) {
        state.list = data
    },
}

const actions = {
    applyCoupon({ dispatch }, code) {
        return api
            .post(`/coupons/apply/${code}`)
            .then(({ data }) => data)
            .catch(e => dispatch('handleError', e))
    },
    getCouponsList({ dispatch }, search = '') {
        return api
            .post('/coupons/list/valid', { search })
            .then(({ data }) => data)
            .catch(e => dispatch('handleError', e))
    },
}

export default {
    namespaced: false,
    state,
    actions,
    mutations,
}
