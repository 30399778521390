import api from '@/api'

const state = {
    list: [],
}

const mutations = {
    RESET(state) {
        state.list = {}
    },
    SET_INVOICES(state, data) {
        state.list = data
    },
}

const actions = {
    getConnectInvoices({ commit, dispatch }, params = {}) {
        let { pagination, status, search } = params
        pagination ? delete pagination.totalItems : false

        let filters = {}
        switch (status) {
            case 'outstanding':
                filters = {
                    status: 'open',
                }
                break
            case 'past_due':
                filters = {
                    past_due: true,
                }
                break
            case 'paid':
                filters = {
                    status: 'paid',
                }
                break
            default:
                filters = {}
        }
        // filters['include[]'] = 'total_count'
        // filters.limit = pagination.limit
        // starting after is a cursor. from docs: starting_after is an object ID that defines your place in the list. For instance, if you make a list request and receive 100 objects, ending with obj_foo, your subsequent call can include starting_after=obj_foo in order to fetch the next page of the list.

        // filters.starting_after = state.list.length ? state.list[filters.limit - 1].id : null

        return api
            .get('/invoices', { params: { ...pagination, filters, search } })
            .then(invoices => {
                commit('SET_INVOICES', invoices)
                return invoices
            })
            .catch(e => dispatch('handleError', e))
    },
}

export default {
    namespaced: false,
    state,
    actions,
    mutations,
}
