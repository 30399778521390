const state = {
    next_route: null,
    prevent_navigation: false,
}

const mutations = {
    RESET(state) {
        state.next_route = null
        state.prevent_navigation = false
    },
    SET_NEXT_ROUTE(state, data) {
        state.next_route = data
    },
    SET_PREVENT_NAVIGATION(state, data) {
        state.prevent_navigation = data
    },
}
const actions = {
    setNextRoute({ commit }, data) {
        commit('SET_NEXT_ROUTE', data)
    },
    setPreventNavigation({ commit }, data) {
        commit('SET_PREVENT_NAVIGATION', !!data)
    },
}

export default {
    namespaced: false,
    state,
    actions,
    mutations,
}
