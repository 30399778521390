<script>
import { ResizeObserver } from '@juggle/resize-observer'

export default {
    props: {
        title: String,
        fullLoading: Boolean, // what is this?
        loading: Boolean,
    },
    data() {
        return {
            scrollHeight: 0,
            offsetHeight: 0,
            scrollTop: 0,
            actionsHeight: 0,
            stickBottom: false,
            // containerMutationObserver: new MutationObserver(this.onResize),
            containerResizeObserver: new ResizeObserver(this.onResize),
        }
    },

    mounted() {
        // this.containerMutationObserver.observe(this.$el, {
        //     subtree: true,
        //     childList: true,
        //     attributeFilter: true,
        //     characterData: true,
        // })

        this.$nextTick(() => {
            this.containerResizeObserver.observe(this.$el)

            if (this.$refs['content']) this.containerResizeObserver.observe(this.$refs['content'])

            this.onResize()
        })
    },
    watch: {
        fullLoading() {
            if (!this.fullLoading) {
                this.$nextTick(() => {
                    this.containerResizeObserver.observe(this.$refs['content'])
                })
            }
        },
    },
    beforeDestroy() {
        // this.containerMutationObserver.disconnect()
        this.containerResizeObserver.disconnect()
    },
    methods: {
        onResize() {
            window.requestAnimationFrame(() => {
                if (this.$el) {
                    this.scrollHeight = this.$el.scrollHeight
                    this.offsetHeight = this.$el.offsetHeight
                    this.actionsHeight =
                        (this.$refs['actions'] && this.$refs['actions'].offsetHeight) || 0

                    this.checkStickBottom()
                }
            })
        },
        onScroll() {
            this.scrollTop = this.$el.scrollTop
            this.onResize()
        },
        checkStickBottom() {
            if (this.scrollTop + this.offsetHeight >= this.scrollHeight) {
                this.stickBottom = false
            } else {
                this.stickBottom = true
            }
        },
    },
}
</script>

<template>
    <div @scroll="onScroll" class="k-drawer-container">
        <v-card
            ref="container"
            v-if="!fullLoading"
            :loading="loading"
            class="k-drawer-card elevation-0 rounded-0 pa-0"
        >
            <v-card-title class="px-8 px-md-10">
                <slot v-if="$scopedSlots['title']" name="title" />
                <div v-else class="first-capitalize">{{ title }}</div>
            </v-card-title>
            <v-card-text ref="tabs" class="px-0">
                <slot name="tabs" />
            </v-card-text>
            <v-card-text ref="content" class="px-8 px-md-10">
                <slot name="content" v-bind="{ stickBottom }" />
            </v-card-text>
            <v-spacer />
            <v-card-actions
                v-if="$scopedSlots['actions']"
                ref="actions"
                class="actions px-8 px-md-10 py-4"
                :class="{ 'is-stick': stickBottom }"
            >
                <slot name="actions" />
            </v-card-actions>
        </v-card>
        <div v-else class="k-drawer-container__loader">
            <spinner />
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import 'vuetify/src/styles/styles.sass';

.k-drawer-container {
    overflow-y: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    &__loader {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
    }
    .actions {
        z-index: 1;
    }
}
.k-drawer-card {
    display: flex;
    min-height: 100%;
    flex-direction: column;
    overflow-y: visible;
    position: relative;

    .is-stick {
        box-shadow: 0px -4px 8px rgba(map-get($shades, 'black'), 0.2);
        position: sticky;
        bottom: 0;
        left: 0;
        right: 0;
        background: map-get($shades, 'white');
    }
}
</style>
