<script>
import {
    addMonths,
    subMonths,
    format,
    endOfMonth,
    startOfMonth,
    differenceInMonths,
} from 'date-fns'
import ja from 'date-fns/locale/ja'
import en from 'date-fns/locale/en'

export default {
    props: {
        time: { type: String, default: 'all' },
        startDate: { type: [Date, String] },
        maxDate: { type: [Date, String] },
        minDate: { type: [Date, String] },
    },
    data() {
        return {
            date: new Date(),
        }
    },
    mounted() {
        if (this.startDate) this.date = this.startDate
    },
    computed: {
        diff_now() {
            return differenceInMonths(new Date(), this.date)
        },
        display_month() {
            return format(this.date, 'MMM YYYY', { locale: this.$root.is_japanese ? ja : en })
        },
        disabled_prev() {
            if (differenceInMonths(this.date, this.minDate) <= 0) return true
            if (this.time === 'future') return this.diff_now > 0
            return false
        },
        disabled_next() {
            if (differenceInMonths(this.date, this.maxDate) >= 0) return true
            if (this.time === 'past') return this.diff_now <= 0
            return false
        },
    },
    methods: {
        next() {
            this.date = addMonths(this.date, 1)
            this.onChange()
        },
        prev() {
            this.date = subMonths(this.date, 1)
            this.onChange()
        },
        onChange() {
            this.$emit('change', {
                from: format(startOfMonth(this.date), 'YYYY-MM-DD'),
                to: format(endOfMonth(this.date), 'YYYY-MM-DD'),
                date: this.date,
            })
        },
    },
}
</script>

<template>
    <div class="pa-3 d-flex align-center">
        <v-btn
            @click="prev"
            :disabled="disabled_prev"
            class="px-0"
            style="min-width: 36px; background-color: #fff"
        >
            <v-icon class="grey--text text--darken-1">chevron_left</v-icon>
        </v-btn>
        <div
            class="text-center text-body-2 mx-2 text-no-wrap grey--text text--darken-1"
            style="min-width: 108px"
        >
            {{ display_month }}
        </div>
        <v-btn
            @click="next"
            :disabled="disabled_next"
            class="px-0"
            style="min-width: 36px; background-color: #fff"
        >
            <v-icon class="grey--text text--darken-1">chevron_right</v-icon>
        </v-btn>
    </div>
</template>
