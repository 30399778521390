export default function humanize(num, locale, count = false) {
    if (num == null) return 'N/A'
    if (typeof num == 'undefined') return
    if (locale == 'ja') return num + (count ? '回目' : '') + '日'
    if (num % 100 >= 11 && num % 100 <= 13) return num + 'th'

    switch (num % 10) {
        case 1:
            return num + 'st'
        case 2:
            return num + 'nd'
        case 3:
            return num + 'rd'
    }
    return num + 'th'
}
