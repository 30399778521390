<template>
    <section class="kustomer-feedbacks">
        <div
            class="kustomer-feedback"
            v-for="(feedback, type) in feedbacks"
            :key="type"
            @click="setFeedbackType(feedback, type)"
        >
            <img :src="feedback.icon" :alt="feedback.title | translate" />
            <p>{{ label(type) | translate }}</p>
        </div>
    </section>
</template>

<script>
export default {
    props: ['feedbacks', 'labels'],

    methods: {
        setFeedbackType(feedback, type) {
            feedback.type = type
            this.$emit('selected', feedback)
        },
        label(type) {
            return (this.labels.feedbacks[type] && this.labels.feedbacks[type].title) || 'unknown'
        },
    },
}
</script>
