<script>
export default {
    inheritAttrs: false,
}
</script>

<template>
    <v-list-item-subtitle
        class="k-list-item-subtitle first-capitalize"
        v-bind="$attrs"
        v-on="$listeners"
    >
        <slot />
    </v-list-item-subtitle>
</template>

<style lang="scss" scoped>
.k-list-item-subtitle {
    font-size: 13px;
    font-weight: 400;
    line-height: 24px !important;
    letter-spacing: 0px;
}
</style>
