import api from '@/api'

import string_hash from '@/utils/string-hash'

const state = {
    metrics: {},
}

const mutations = {
    RESET(state) {
        state.metrics = {}
    },
    SET_METRICS(state, data) {
        state.metrics[data.store_key] = data.data
    },
}

const getters = {}

const actions = {
    getTrendMetric({ commit, dispatch, state }, options = {}) {
        let { endpoint, query, range, resource } = options
        query = query ? query : {}
        let params = {
            range,
            resource,
            ...query,
        }
        let store_key = string_hash(endpoint + JSON.stringify(params))

        if (store_key in state.metrics) {
            return state.metrics[store_key]
        }

        return api
            .get(`/metrics/${endpoint}/`, { params })
            .then(data => {
                commit('SET_METRICS', { store_key, data })
                return data
            })
            .catch(e => dispatch('handleError', e))
    },

    getPartitionMetric({ commit, dispatch }, options = {}) {
        let params = { resource: options.resource }
        let store_key = string_hash(options.endpoint + JSON.stringify(params))

        if (store_key in state.metrics) {
            return state.metrics[store_key]
        }
        return api
            .get(`/metrics/${options.endpoint}/`, { params })
            .then(data => {
                commit('SET_METRICS', { store_key, data: data.value })
                return data.value
            })
            .catch(e => dispatch('handleError', e))
    },

    getValueMetric({ commit, dispatch }, options = {}) {
        let params = {
            range: options.range,
            resource: options.resource,
        }
        let store_key = string_hash(options.endpoint + JSON.stringify(params))

        if (store_key in state.metrics) {
            return state.metrics[store_key]
        }
        return api
            .get(`/metrics/${options.endpoint}/`, { params })
            .then(data => {
                commit('SET_METRICS', { store_key, data })
                return data
            })
            .catch(e => dispatch('handleError', e))
    },
}

export default {
    namespaced: false,
    state,
    actions,
    getters,
    mutations,
}
