import api from '@/api'
import pick from 'lodash/pick'

const state = {
    list: null,
    item: null,
}

const mutations = {
    SET_CAMPAIGNS(state, data) {
        state.list = data
    },
    SET_CAMPAIGN(state, data) {
        state.item = data
    },
}

const actions = {
    getCampaigns({ commit, dispatch }, params = {}) {
        let { pagination, search, filters, sent } = params
        pagination ? delete pagination.totalItems : false
        return api
            .get('/campaigns', { params: { ...pagination, search, filters, sent } })
            .then(data => {
                commit('SET_CAMPAIGNS', data)
                return data
            })
            .catch(e => dispatch('handleError', e))
    },
    getCampaign({ commit, dispatch }, id) {
        return api
            .get(`/campaigns/${id}`)
            .then(({ data }) => {
                commit('SET_CAMPAIGN', data)
                return data
            })
            .catch(e => dispatch('handleError', e))
    },
    createCampaign({ dispatch }, campaign) {
        delete campaign.contact_list
        return api
            .post('/campaigns', campaign)
            .then(response => {
                dispatch('getCampaigns')
                return response.data
            })
            .catch(e => dispatch('handleError', e))
    },
    updateCampaign({ dispatch }, campaign) {
        delete campaign.contact_list
        return api
            .patch(`/campaigns/${campaign.id}`, serializeCampaign(campaign))
            .then(response => response.data)
            .catch(e => dispatch('handleError', e))
    },
    sendCampaign({ dispatch }, { id, protocol, scheduled_at }) {
        return api
            .post(`/campaigns/${id}/send`, { protocol, scheduled_at })
            .catch(e => dispatch('handleError', e))
    },
    deleteCampaign({ dispatch }, id) {
        return api.delete(`/campaigns/${id}`, {}).catch(e => dispatch('handleError', e))
    },
    getRecipients({ dispatch }, { id, pagination, search }) {
        return api
            .get(`/campaigns/${id}/recipients`, { params: { ...pagination, search } })
            .catch(e => dispatch('handleError', e))
    },
    sendTestCampaign({ dispatch }, id) {
        return api.post(`/campaigns/${id}/test`).catch(e => dispatch('handleError', e))
    },
}

export default {
    namespaced: false,
    state,
    actions,
    mutations,
}

// omits all extra fields in request
function serializeCampaign(campaign) {
    const data = pick(campaign, [
        'id',
        'name',
        'email_subject',
        'email_template',
        'scheduled_at',
        'contact_list_id',
    ])

    return data
}
