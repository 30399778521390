<script>
import { format } from 'date-fns'

import humanize from '@/utils/humanize.js'
import TimeDuration from '@/components/timeDuration.vue'
import FormValidationRules from '@/mixins/formValidationRules.vue'

export default {
    mixins: [FormValidationRules],
    components: { TimeDuration },
    props: {
        value: Object,
        minDate: {
            type: [Date, undefined],
            default: () => {
                let date = new Date()
                date.setHours(0, 0, 0)
                return date
            },
        },
        maxDate: Date,
        outlined: Boolean,
        fullWidthMode: Boolean,
        noShift: Boolean,
    },
    data() {
        return {
            // sales limits
            sales: undefined,
            hasSalesLimits: false,
        }
    },
    watch: {
        sales: {
            handler(value) {
                if (!this.value) return
                if (!value || !value.mode) {
                    this.$set(this.value, 'sales_start', null)
                    this.$set(this.value, 'sales', null)
                    this.$emit('input', this.value)
                    return
                }

                if (this.is_sales_date) {
                    this.$set(
                        this.value,
                        'sales_start',
                        new Date(`${value.start_date}T${value.start_time}`)
                    )
                } else {
                    this.$set(this.value, 'sales_start', null)
                }

                this.$set(this.value, 'sales', value)
                this.$emit('input', this.value)
            },
            deep: true,
        },
    },
    computed: {
        default_sales() {
            return {
                mode: null,
                from: null,
                to: null,
                start_date: null,
                start_time: null,
            }
        },
        sales_options() {
            let options = [
                { value: null, text: this.$c(this.$t('not defined')) },
                { value: 'date', text: this.$c(this.$t('fixed date')) },
                // { value: 'period', text: this.$t('fixed period') },
            ]
            if (!this.noShift) {
                options.push({ value: 'shift', text: this.$c(this.$t('shifting period')) })
            }
            return options
        },
        is_sales_date() {
            return this.sales?.mode === 'date'
        },
        is_sales_period() {
            return this.sales?.mode === 'period'
        },
        is_sales_shift() {
            return this.sales?.mode === 'shift'
        },
        day_items() {
            let items = []
            for (var value = 1; value <= 31; value++) {
                items.push({
                    value: String(value),
                    label: humanize(value, this.$root.lang),
                })
            }

            return items
        },
        is_edit() {
            return !!this.value?.id
        },
        min_date() {
            if (this.is_edit) {
                return null
            }
            return this.minDate
        },
    },
    mounted() {
        this.sales = this.default_sales
        this.value && this.initStartVariables(this.value)
    },
    methods: {
        initStartVariables({ sales_start, sales }) {
            if (sales?.mode) {
                this.sales = sales
            }
            if (sales_start && !sales?.mode) {
                this.sales.mode = 'date'
                this.sales.start_date = format(sales_start, 'YYYY-MM-DD')
                this.sales.start_time = format(sales_start, 'HH:mm')
            }

            if (this.is_edit) {
                if (sales_start) this.hasSalesLimits = true
            }
        },
        onHasSalesLimitsChange(hasSalesLimits) {
            if (!hasSalesLimits) {
                this.sales = this.default_sales
                this.$set(this.value, 'sales_start', null)
                this.$set(this.value, 'sales', null)
                this.$emit('input', this.value)
                return
            }
            if (this.is_sales_period) {
                this.sales.start_date = this.sales.start_time = null
            } else if (this.is_sales_date) {
                this.sales.from = this.sales.to = null
            }
        },
    },
}
</script>

<template>
    <v-row v-if="sales" dense>
        <v-col sm="12" :md="fullWidthMode ? 12 : 4">
            <v-select
                v-model="sales.mode"
                :items="sales_options"
                :label="$t('Sales start/end date constraints')"
                :outlined="outlined"
                @change="onHasSalesLimitsChange"
            />
        </v-col>
        <v-col v-if="is_sales_date" cols="12" :md="fullWidthMode ? 12 : 6">
            <v-row dense>
                <v-col cols="6">
                    <k-date-field
                        v-model="sales.start_date"
                        :label="$t('Sales start')"
                        :min-date="min_date"
                        :max-date="maxDate"
                        :rules="[rules.required]"
                        :outlined="outlined"
                        fast-valid
                    />
                </v-col>
                <v-col cols="6">
                    <k-date-field
                        v-model="sales.start_time"
                        :label="$t('start time')"
                        :outlined="outlined"
                        width="100"
                        mode="time"
                    />
                </v-col>
            </v-row>
            <v-row dense>
                <v-col cols="6">
                    <k-date-field
                        v-model="sales.end_date"
                        :label="$t('Sales end')"
                        :min-date="min_date"
                        :max-date="maxDate"
                        :outlined="outlined"
                        fast-valid
                    />
                </v-col>
                <v-col cols="6">
                    <k-date-field
                        v-model="sales.end_time"
                        :label="$t('end time')"
                        :outlined="outlined"
                        width="100"
                        mode="time"
                    />
                </v-col>
            </v-row>
        </v-col>

        <v-col v-else-if="is_sales_period" cols="12" :md="fullWidthMode ? 12 : 6">
            <time-duration
                v-model="sales.from"
                :label="$t('Sales start')"
                :only-units-of-time="['days', 'weeks', 'months']"
                :suffix="$t('before')"
                :outlined="outlined"
            />
            <time-duration
                v-model="sales.to"
                :label="$t('Sales end')"
                :only-units-of-time="['weeks', 'days', 'hours']"
                :outlined="outlined"
                with-zero
                with-after
            />
        </v-col>

        <v-col v-else-if="is_sales_shift" cols="12" :md="fullWidthMode ? 12 : 6">
            <v-row dense>
                <v-col cols="6">
                    <v-select
                        v-model="sales.from"
                        :label="$t('Sales start')"
                        :items="day_items"
                        :outlined="outlined"
                        item-value="value"
                        item-text="label"
                    />
                    <k-date-field
                        v-model="sales.start_time"
                        :label="$t('start time')"
                        :outlined="outlined"
                        width="100"
                        mode="time"
                        fast-valid
                    />
                </v-col>
                <v-col cols="6">
                    <v-select
                        v-model="sales.to"
                        :label="$t('Sales end')"
                        :items="day_items"
                        :outlined="outlined"
                        item-value="value"
                        item-text="label"
                    />
                    <k-date-field
                        v-model="sales.end_time"
                        :label="$t('end time')"
                        :outlined="outlined"
                        width="100"
                        mode="time"
                        fast-valid
                    />
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>
