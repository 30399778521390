<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import distanceInWordsStrict from 'date-fns/distance_in_words_strict'
import parse from 'date-fns/parse'
import ja from 'date-fns/locale/ja'

import WorkspaceSwitcher from '@/components/workspaceSwitcher.vue'

export default {
    components: { WorkspaceSwitcher },
    props: {
        withoutSidebar: { type: Boolean, default: false },
        hideAccount: { type: Boolean, default: false },
    },
    data() {
        return {
            beaconIntervalId: null,
        }
    },
    computed: {
        ...mapState({
            user: state => state.account.user,
        }),
        ...mapGetters([
            'is_subscribed',
            'has_context',
            'shadow_role',
            'on_trial',
            'signed_in',
            'is_squareup_mode',
            'staff_venue_mode',
            'venue_manager_mode',
            'editor_mode',
            'is_full_company_business_info',
        ]),
        has_member_rights() {
            return this.$store.getters.has_member_rights
        },
        show_menu() {
            return this.$vuetify.breakpoint.smAndDown && this.signed_in
        },
        origin_user() {
            return this.user.origin ? this.user.origin : this.user
        },
        show_upgrade_warning() {
            return (
                (!this.is_subscribed && this.user.trial_ended) ||
                (this.on_trial && this.$vuetify.breakpoint.xsOnly) ||
                (this.has_context &&
                    !this.is_full_company_business_info &&
                    this.shadow_role &&
                    this.shadow_role !== 'owner')
            )
        },
        lang_index() {
            switch (this.$root.lang) {
                case 'en':
                    return 0
                case 'pl':
                    return 2
                default:
                    return 1
            }
        },
        show_switcher() {
            return (
                (this.venue_manager_mode || this.staff_venue_mode || this.editor_mode) &&
                this.signed_in &&
                !this.is_squareup_mode
            )
        },
    },
    methods: {
        ...mapActions(['selectVenue']),
        toggleDrawer() {
            this.$emit('toggleDrawer')
        },
        relative(time) {
            let locale = null
            if (this.$root.is_japanese) {
                locale = ja
            }
            return distanceInWordsStrict(new Date(), parse(time), { locale })
        },
        cleanMatomoConsent() {
            localStorage.removeItem('cookieConsent')
            location.reload()
        },
    },
}
</script>

<template>
    <div>
        <div class="global-header px-4 px-sm-6 px-md-10">
            <v-app-bar-nav-icon
                v-if="has_member_rights && !editor_mode && !withoutSidebar"
                @click.stop="toggleDrawer"
                class="mr-4"
            />
            <router-link to="/">
                <img
                    src="@/assets/images/kinchaku_logo.svg"
                    alt="KINCHAKU"
                    height="18"
                    class="d-block"
                />
            </router-link>
            <v-spacer />
            <workspace-switcher
                v-if="show_switcher"
                class="mx-1 mx-sm-4"
                only-multiple
                with-small
            />
            <v-chip small v-if="on_trial && $vuetify.breakpoint.smAndUp" class="trial-message mx-4">
                {{ $t('Trial ends in {in_time}', { in_time: relative(user.trial_ends_at) }) }}
            </v-chip>
            <div class="grey--text text--darken-1 d-flex">
                <v-menu offset-y top left>
                    <template v-slot:activator="{ on: menu }">
                        <v-tooltip max-width="300" left>
                            <template v-slot:activator="{ on: tooltip }">
                                <v-btn
                                    id="btn-language"
                                    class="ml-sm-2"
                                    icon
                                    v-on="{ ...tooltip, ...menu }"
                                >
                                    <v-icon>language</v-icon>
                                </v-btn>
                            </template>
                            <span>Change language</span>
                        </v-tooltip>
                    </template>
                    <v-list class="py-0">
                        <v-list-item-group :value="lang_index" color="primary">
                            <v-list-item @click="$emit('language', 'en')">
                                <v-list-item-icon>
                                    <i class="flag-icon flag-icon-gb" />
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>English</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="$emit('language', 'ja')">
                                <v-list-item-icon>
                                    <i class="flag-icon flag-icon-jp" />
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>日本語</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-menu>
                <!-- TODO: currently it checks if user is signed into a workspace-->
                <template v-if="signed_in && !hideAccount">
                    <v-divider class="ml-4 mr-2 my-1" vertical />
                    <v-btn to="/account" icon>
                        <k-icon>mdi-account-circle-outline</k-icon>
                    </v-btn>
                </template>

                <v-btn v-if="!signed_in" to="/signin" text>
                    <div v-if="$vuetify.breakpoint.smAndUp">{{ $t('sign in') }}</div>
                    <v-icon class="ml-2">lock_outline</v-icon>
                </v-btn>
            </div>
        </div>
        <div v-if="show_upgrade_warning" class="header-alert">
            <div v-if="on_trial && $vuetify.breakpoint.xsOnly">
                {{ $t('Trial ends in {in_time}', { in_time: relative(user.trial_ends_at) }) }}
            </div>
            <router-link v-if="!is_subscribed && user.trial_ended" to="/billing">
                <template v-if="$root.is_japanese">
                    トライアルは終了しましたが、Kinchakuのプレミアム機能へのアクセスするためにアップグレードが可能です。
                    アクセスを維持するために今すぐアップグレードしてください。
                </template>
                <template v-else>
                    Upgrade now to keep access. Your trial has ended, but you can still upgrade to
                    regain access to Kinchaku`s premium features.
                </template>
            </router-link>
            <div
                v-if="
                    has_context &&
                    !is_full_company_business_info &&
                    shadow_role &&
                    shadow_role !== 'owner'
                "
            >
                <template v-if="$root.is_japanese">
                    ワークスペースのオーナーに事業者情報登録を完成させるようにお願いします。
                </template>
                <template v-else>
                    Please request the workspace owner to register the business information.
                </template>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import 'vuetify/src/styles/styles.sass';

.global-header::v-deep {
    z-index: 1;
    display: flex;
    height: 64px;
    align-items: center;
    border-bottom: 1px solid map-get($grey, 'lighten-2');

    .v-btn--active {
        &::before {
            background-color: transparent !important;
        }
    }
}

.v-list__tile__avatar {
    min-width: 28px !important;
}
.v-toolbar__extension .v-alert {
    width: 100%;
    position: inherit;
}
.v-sheet::v-deep {
    .v-toolbar__extension {
        background-color: #e5aa17;
        a {
            color: white;
        }
    }
}
.v-alert a {
    color: white;
    text-decoration: underline;
}
.logo {
    color: white !important;
}
.header-toolbar {
    .v-btn {
        min-width: initial !important;
    }
}
.trial-message {
    white-space: normal;
    line-height: 10px;
}

.header-alert {
    padding: 4px;
    background-color: #e5aa17;
    border-bottom: 1px solid map-get($grey, 'lighten-2');
    color: #fff;
    width: 100%;
    text-align: center;

    a {
        color: #fff;
    }
}
</style>
