import api from '@/api'
import { pick, omitBy } from 'lodash'

import { dataURLtoBlob } from '@/utils/data-url'
import { toTokyoTimezone } from '@/utils/format-date'

const state = {}

const mutations = {}

const actions = {
    getPlan({ dispatch }, id) {
        return api
            .get(`/plans/${id}`)
            .then(({ data }) => {
                return data
            })
            .catch(e => dispatch('handleError', e))
    },
    createPlan({ dispatch }, { id, plan }) {
        return api
            .request({
                url: `/products/${id}/plans`,
                method: 'post',
                data: { plan: serializePlan(plan) },
            })
            .then(data => data?.plan || data?.data)
            .catch(e => dispatch('handleError', e))
    },
    updatePlan({ dispatch }, { id, plan }) {
        return api
            .request({
                url: `/products/${id}/plans/${plan.id}`,
                method: 'patch',
                data: { plan: serializePlan(plan) },
            })
            .then(data => data?.plan || data?.data)
            .catch(e => dispatch('handleError', e))
    },
    updatePlanImages({ dispatch }, { project, plan, data }) {
        if (
            !Object.keys(data || {}).length ||
            (data.images &&
                Object.keys(data).length === 1 &&
                !Object.keys(data?.images || {}).length)
        ) {
            return Promise.resolve()
        }

        const formData = new FormData()

        for (const key in data) {
            if (key === 'images') {
                const encodedImages = omitBy(
                    data.images,
                    v => !(typeof v == 'string' && v.indexOf('data:') === 0)
                )
                let images = Object.keys(encodedImages).reduce(
                    (images, key) => ({ ...images, [key]: dataURLtoBlob(encodedImages[key]) }),
                    {}
                )
                for (const key in images) {
                    if (images[key]) {
                        formData.append(`plan[images][${key}]`, images[key])
                    }
                }
            } else {
                formData.append(`plan[${key}]`, data[key])
            }
        }

        return api
            .request({
                url: `/products/${project.id}/plans/${plan.id}/images`,
                headers: { 'Content-Type': 'multipart/form-data' },
                method: 'post',
                data: formData,
            })
            .then(data => data?.plan || data?.data)
            .catch(e => dispatch('handleError', e))
    },
    deletePlan({ dispatch }, id) {
        return api.delete(`/plans/${id}`).catch(e => dispatch('handleError', e))
    },
    archivePlan({ dispatch }, plan) {
        return api
            .patch(`/plans/${plan}/archive`)
            .then(({ data }) => data)
            .catch(e => dispatch('handleError', e))
    },
    unarchivePlan({ dispatch }, plan) {
        return api
            .patch(`/plans/${plan}/unarchive`)
            .then(({ data }) => data)
            .catch(e => dispatch('handleError', e))
    },
    clonePlan({ dispatch }, plan) {
        return api.post(`/plans/${plan}/clone`).catch(e => dispatch('handleError', e))
    },
}

export default {
    namespaced: false,
    state,
    actions,
    mutations,
}

// omits all extra fields in request
function serializePlan(plan) {
    const data = pick(plan, [
        'id',
        'name',
        'name_en',
        'quantity',
        'description',
        'description_en',
        'bundle_venues',
        'interval',
        'interval_count',
        'amount',
        'images',
        'ios_logo_layout',
        'access_details',
        'tax_included',
        'max_redemptions',
        'open_checkin',
        'reward_name',
        'has_coupon',
        'coupon_id',
        'disable_refund',
        'disable_cancel',
        'cancel_policy',
        'availability_constraints',
        'transport',
        'expires_in',
        'expires_at',
        'expires_end',
        'expires_first_activity',
        'expires_last_activity',
        'pass_layout',
        'rewards',
        'addons',
        'with_usage_rewards',
        'with_single_purchase',
        'with_paid_extend',
        'with_payment_methods',
        'payment_methods',
        'regular_hours',
        'special_hours',
        'roundtrip',
        'lawson_category',
        'seat_categories',
        'limit_to_venue',
        'redemption_interval',
        'stamp_icon',
        'reward_icon',
        'colors',
        'sales',
    ])
    if (data.expires_at) data.expires_at = toTokyoTimezone(data.expires_at)

    return data
}
