import api from '@/api'
import { setAccessToken } from '@/auth'

const state = {}

const actions = {
    requestReset({ dispatch }, email) {
        return api
            .post('/auth/password/email', {
                email,
            })
            .then(() => {
                return Promise.resolve('ok')
            })
            .catch(e => dispatch('handleError', e))
    },
    reset({ dispatch }, user) {
        return api
            .post('/auth/password/reset', user)
            .then(response => {
                if (!response.token) {
                    return dispatch('handleError', response)
                }
                setAccessToken(response.token)
                sessionStorage.removeItem('invite_code')

                return true
            })
            .catch(e => dispatch('handleError', e))
    },
    oauthRedirect({ dispatch }, service) {
        return api.get(`/auth/${service}/redirect`, {}).catch(e => dispatch('handleError', e))
    },
    oauthCallback({ dispatch }, { service, params }) {
        return api
            .post(`/auth/${service}/callback`, params)
            .then(response => {
                if (response.integration) {
                    return response.integration
                }
                if (!response.token) {
                    return dispatch('handleError', response)
                }
                sessionStorage.removeItem('invite_code')
                setAccessToken(response.token)
                return response
            })
            .catch(e => dispatch('handleError', e))
    },
    oauthAddEmail({ dispatch }, { service, user }) {
        return api
            .post(`/auth/${service}/email`, user)
            .then(response => {
                if (!response.token) {
                    return dispatch('handleError', response)
                }
                setAccessToken(response.token)
                sessionStorage.removeItem('invite_code')

                return true
            })
            .catch(e => dispatch('handleError', e))
    },

    lockWorkspace({ dispatch }) {
        api.post('/auth/lock', {}).then(() => dispatch('getUser'))
    },

    unlockWorkspace({ dispatch }, user) {
        return api
            .post('/auth/unlock', {
                user,
            })
            .catch(e => dispatch('handleError', e))
    },

    signIn2FA(context, user) {
        if (context.getters.signed_in) return context.dispatch('getUser')

        return api
            .post('/auth/login2fa', {
                user,
            })
            .then(response => {
                setAccessToken(response.token)
                sessionStorage.removeItem('invite_code')
                return true
            })
            .catch(e => context.dispatch('handleError', e))
    },

    generateQRcode({ dispatch }, account) {
        return api.post(`/auth/qrcode/${account}`, {}).catch(e => dispatch('handleError', e))
    },

    generateAccountQRcode({ dispatch }) {
        return api.post(`/auth/qrcode/`, {}).catch(e => dispatch('handleError', e))
    },

    generate2faKey({ dispatch }) {
        return api.get(`/auth/2fa`, {}).catch(e => dispatch('handleError', e))
    },

    enable2fa({ dispatch }, tfa) {
        return api.put('/auth/2fa', tfa).catch(e => dispatch('handleError', e))
    },

    disable2fa({ dispatch }, one_time_password) {
        return api.delete(`/auth/2fa/${one_time_password}`).catch(e => dispatch('handleError', e))
    },
}

export default {
    namespaced: false,
    state,
    actions,
}
