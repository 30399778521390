<script>
export default {
    name: 'k-list-item',
    inheritAttrs: false,
}
</script>

<template>
    <v-list-item v-bind="$attrs" v-on="$listeners">
        <slot />
    </v-list-item>
</template>
