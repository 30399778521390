<script>
export default {
    name: 'k-list-item-content',
    inheritAttrs: false,
}
</script>

<template>
    <v-list-item-content v-bind="$attrs" v-on="$listeners">
        <slot />
    </v-list-item-content>
</template>
