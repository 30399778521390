import router from '@/router'
import store from '@/store'

export const ROLE_OWNER = 'owner'
export const ROLE_MEMBER = 'member'
export const ROLE_OPERATOR = 'operator'
export const ROLE_VENUE_MANAGER = 'venue-manager'
export const ROLE_EDITOR = 'editor'

export const hasAccess = route => {
    const role = store.getters.role
    const { meta: { rbac = {} } = {} } = route
    // TODO: Restrict all after complete rbac integration
    if (role !== ROLE_VENUE_MANAGER && role !== ROLE_EDITOR && role !== ROLE_OPERATOR) return true
    return Object.prototype.hasOwnProperty.call(rbac, role) && !!rbac[role]
}

export const canVisit = raw => {
    return hasAccess(router.match(raw))
}

const makeFor = roles => {
    const RBAC = {
        [ROLE_OWNER]: true,
        [ROLE_MEMBER]: false,
        [ROLE_OPERATOR]: false,
        [ROLE_VENUE_MANAGER]: false,
        [ROLE_EDITOR]: false,
    }
    if (!roles || roles === '*') {
        Object.keys(RBAC).forEach(key => (RBAC[key] = true))
        return RBAC
    }
    return roles.reduce((rbac, role) => {
        rbac[role] = true
        return rbac
    }, RBAC)
}

export default rawRoutes =>
    rawRoutes.map(rawRoute => {
        const meta = rawRoute.meta || {}
        meta.rbac = makeFor(meta.roles)
        return {
            ...rawRoute,
            meta,
        }
    })
