import { local } from './storage'
export const ACCESS_TOKEN_KEY = 'token'

export function getAccessToken() {
    return local.getItem(ACCESS_TOKEN_KEY)
}

export function clearAccessToken() {
    local.removeItem(ACCESS_TOKEN_KEY)
}

export function setAccessToken(accessToken) {
    local.setItem(ACCESS_TOKEN_KEY, accessToken)
}

export function hasToken() {
    const accessToken = getAccessToken()
    return !!accessToken
}
