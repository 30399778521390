import api from '@/api'
import { pick, omitBy } from 'lodash'

import { dataURLtoBlob } from '@/utils/data-url'
import { jsonToFormData } from '@/utils/formdata'
import { toTokyoTimezone } from '@/utils/format-date'

const actions = {
    updateGroupTemplate({ dispatch }, template) {
        const formData = jsonToFormData({ template: serializeTemplate(template) })
        formData.append('_method', 'PUT')

        return api
            .request({
                url: `/templates/${template.id}`,
                method: 'post',
                headers: { 'Content-Type': 'multipart/form-data' },
                data: formData,
            })
            .then(({ data }) => data)
            .catch(e => dispatch('handleError', e))
    },
}

export default {
    namespaced: false,
    actions,
}

// omits all extra fields in request
function serializeTemplate(template) {
    const data = pick(template, [
        'name',
        'name_en',
        'colors',

        'description',
        'description_en',

        'images',
        'ios_logo_layout',

        'disable_refund',
        'disable_cancel',
        'cancel_policy',

        'expires_in',
        'expires_at',
        'expires_end',
        'expires_first_activity',
        'expires_last_activity',
        'pass_layout',
    ])

    const encodedImages = omitBy(
        data.images,
        v => !(typeof v == 'string' && v.indexOf('data:') === 0)
    )
    let images = Object.keys(encodedImages).reduce(
        (images, key) => ({ ...images, [key]: dataURLtoBlob(encodedImages[key]) }),
        {}
    )

    if (data.expires_at) data.expires_at = toTokyoTimezone(data.expires_at)

    return { ...data, images }
}
