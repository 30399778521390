<script>
export default {
    functional: true,

    render(h, { data, children }) {
        const classes = 'title grey-text text-darken-1 text-capitalize'
        data.staticClass = classes + (data['staticClass'] ? ' ' + data.staticClass.trim() : '')
        data = {
            ...classes,
            ...data,
        }
        return h('v-card-title', data, children)
    },
}
</script>
