import api from '@/api'

const state = {}

const mutations = {}

const getters = {}

const actions = {
    async getItem({ dispatch }, id) {
        try {
            dispatch('resetError', null, { root: true })
            const { data } = await api.get(`/kyc/${id}`)
            return data
        } catch (e) {
            dispatch('handleError', e, { root: true })
        }
    },
    async getItems({ dispatch }, params = {}) {
        try {
            dispatch('resetError', null, { root: true })
            let { pagination, search, filters } = params
            pagination && delete pagination.totalItems
            const body = { params: { ...pagination, search, filters } }
            return api.get('/kyc', body)
        } catch (e) {
            dispatch('handleError', e, { root: true })
        }
    },
    async decline({ dispatch }, { id, comment }) {
        try {
            dispatch('resetError', null, { root: true })
            const { data } = await api.post(`/kyc/${id}/decline`, { comment })
            return data
        } catch (e) {
            dispatch('handleError', e, { root: true })
        }
    },
    async approve({ dispatch }, id) {
        try {
            dispatch('resetError', null, { root: true })
            const { data } = await api.post(`/kyc/${id}/approve`)
            return data
        } catch (e) {
            dispatch('handleError', e, { root: true })
        }
    },
    async reject({ dispatch }, { id, comment }) {
        try {
            dispatch('resetError', null, { root: true })
            const { data } = await api.post(`/kyc/${id}/reject`, { comment })
            return data
        } catch (e) {
            dispatch('handleError', e, { root: true })
        }
    },
}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
}
