export default {
    tabText: '    ',
    strongText: '太字テキスト',
    italicText: '強調テキスト',
    strikethroughText: '取り消し線のテキスト',
    headingText: 'ここにタイトルを入力',
    lineText: '----',
    quoteText: 'ここで引用',
    codeText: 'ここにコードを入力',
    linkText: 'リンクの説明',
    linkTip: 'リンクアドレスを入力',
    imageText: '画像説明',
    imageTip: '画像アドレスを入力',
    imageTitle: '画像タイトル',
    tableText: 'ヘッダー1 | ヘッダー2\n---|---\n行1列1 | 行1列2\n行2列1 | 行2列2\n',
    ulText: 'ここにテキストをリストアップ',
    olText: 'ここにテキストをリストアップ',
}
