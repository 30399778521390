import api from '@/api'

const state = {
    list: null,
}

const mutations = {
    SET_LISTS(state, data) {
        state.list = data
    },
}

const actions = {
    getContactLists({ commit, dispatch }) {
        return api
            .get('/contact_lists')
            .then(({ data }) => {
                commit('SET_LISTS', data)
                return data
            })
            .catch(e => dispatch('handleError', e))
    },
    getContactList({ dispatch }, id) {
        return api
            .get(`/contact_lists/${id}`)
            .then(response => {
                return response.data
            })
            .catch(e => dispatch('handleError', e))
    },
    createContactList({ dispatch }, list) {
        return api
            .post('/contact_lists', list)
            .then(response => {
                dispatch('getContactLists')
                return response.data
            })
            .catch(e => dispatch('handleError', e))
    },
    updateContactList({ dispatch }, list) {
        return api
            .patch(`/contact_lists/${list.id}`, list)
            .then(response => {
                dispatch('getContactLists')
                return response.data
            })
            .catch(e => dispatch('handleError', e))
    },
    deleteContactList({ dispatch }, id) {
        return api
            .delete(`/contact_lists/${id}`)
            .then(response => {
                dispatch('getContactLists')
                return response
            })
            .catch(e => dispatch('handleError', e))
    },
}

export default {
    namespaced: false,
    state,
    actions,
    mutations,
}
