<script>
export default {
    inheritAttrs: false,
}
</script>

<template>
    <v-list-item-title class="k-list-item-title" v-bind="$attrs" v-on="$listeners">
        <slot />
    </v-list-item-title>
</template>

<style lang="scss" scoped>
.k-list-item-title {
    font-size: 16px;
    font-weight: 550;
    line-height: 24px;
    letter-spacing: 0px;
}
</style>
