<script>
export default {}
</script>

<template>
    <v-select v-bind="$attrs" v-on="$listeners">
        <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
        <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
            <slot :name="name" v-bind="slotData" />
        </template>
    </v-select>
</template>

<style lang="scss">
@import 'vuetify/src/styles/settings/_variables';
</style>
